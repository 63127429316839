import request from '@/utils/request'

// 常见问题
export function comProblem(data) {
  return request({
    url: ' /api/sale/platfcfg/commproblem/page',
    method: 'post',
    data
  })
}
// 常见问题详情
export function comProblemDetail(data) {
  return request({
    url: ' /api/sale/platfcfg/commproblem/find',
    method: 'post',
    data
  })
}





