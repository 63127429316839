<template>
  <div class="probContainer">
    <div class="probInner">
      <div class="prob_header_title">
        <div>当前位置:
          <router-link to="/" active-class="active" exact-active-class="active">众惠首页</router-link>
          >帮助中心
        </div>
      </div>
      <div class="prob_left_title">
        <a href="javascript:;" @click="selection = true">常见问题</a>
        <a href="javascript:;" @click="selection = false">意见反馈</a>
      </div>
      <div class="probContent" v-if="selection">
        <div class="probContent_title">
          <a href="javascript:;" @click="goComProblem">>常见问题列表</a>
        </div>
        <div class="probContent_detail">
          <div>{{ problem.problem }}</div>
          <div>{{ problem.content }}</div>
        </div>
      </div>
      <div class="feedContent" v-if="!selection">
        <feed></feed>
      </div>
    </div>
  </div>
</template>

<script>
import {comProblemDetail} from "../../../api/commProblem";
import feed from "../../../views/home/feedback/Feedback"

export default {
  name: "CommProblem",
  data() {
    return {
      selection: true, //true代表常见问题, false代表意见反馈
      problem: {}
    };
  },
  components: {
    feed,
  },
  mounted() {
    this.comProblem();
  },
  methods: {
    comProblem() {
      //获取常见问题详情
      comProblemDetail({
        id: this.getParameter(),
      }).then(res => {
        this.problem = res.data;
      })
    },
    //获取url参数
    getParameter() {
      let url = window.location.href;
      let id = url.substring(url.lastIndexOf("=") + 1);
      console.log(id)
      return id;
    },
    //返回常见问题页面
    goComProblem() {
      this.$router.push({
        name: "CommProblem"
      })
    }
  },
};
</script>

<style scoped>
.probContainer {
  width: 1440px;
  margin: auto;
  background: #ffffff;
}


</style>
